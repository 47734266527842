<template>
  <svg
    :style="{ width: computedHeight, height: computedHeight, color }"
    viewBox="25 25 50 50"
    class="modern-color-theme a-spinner a-spinner-mat"
    data-component-name="VSpinner"
  >
    <circle
      class="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      stroke="currentColor"
      :stroke-width="props.thickness"
      stroke-miterlimit="10"
    />
  </svg>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import type { ColorWithShade, Size } from '../../utils/types' 
import { convertColorClassToCSS } from '../../utils/utils';

defineOptions({
  name: 'VSpinner'
})

const props = withDefaults(
  defineProps<{
    size?: Size
    color?: ColorWithShade
    thickness?: number
  }>(),
  {
    thickness: 5,
    size: undefined,
    color: 'primary'
  }
)

const computedHeight = computed(() => {
  switch (props.size ?? 'base') {
    case 'xs':
      return '16px'
    case 'sm':
      return '20px'
    case 'base':
      return '24px'
    case 'lg':
      return '28px'
    case 'xl':
      return '32px'
    case '2xl':
      return '36px'
    case '3xl':
      return '40px'
    case '4xl':
      return '44px'
    case '5xl':
      return '48px'
    case '6xl':
      return '52px'
    default:
      return undefined
  }
})

const color = computed(() => convertColorClassToCSS(props.color, 500))
</script>
<style lang="scss">
.a-spinner {
  vertical-align: middle;
}

.a-spinner-mat {
  animation: a-spin 2s linear infinite;
  transform-origin: center center;
  .path {
    stroke-dasharray: 1, 200 #{'/* rtl:ignore */'};
    stroke-dashoffset: 0 #{'/* rtl:ignore */'};
    animation: a-mat-dash 1.5s ease-in-out infinite;
  }
}

@keyframes a-spin {
  0% {
    transform: rotate3d(0, 0, 1, 0deg) #{'/* rtl:ignore */'};
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg) #{'/* rtl:ignore */'};
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg) #{'/* rtl:ignore */'};
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg) #{'/* rtl:ignore */'};
  }
  100% {
    transform: rotate3d(0, 0, 1, 359deg) #{'/* rtl:ignore */'};
  }
}

@keyframes a-mat-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
