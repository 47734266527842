import { ref } from "vue"
import { type Color, type ColorShade, type ColorWithShade } from "./types"
import { UUID } from "@avvoka/shared"

const getUUID = () => {
  /**
   * Lazy fix for crypto#randomUUID not available for localhost when aliased
   */
  if (import.meta.env.PROD) {
    return window.crypto.randomUUID()
  } else {
    return UUID.new()
  }
}

export function useElementId (id: string | undefined) {
  return ref(id || `el-${getUUID()}`)
}

const createCSSColorVariable = (color: string, shade?: number) => `var(--avv-${color}-${shade ? '' : '-color'}${shade || ''})`

export function convertColorClassToCSS (color: ColorWithShade | undefined, defaultShade?: ColorShade) {
  if (color === 'white' || color === 'black') return color
  else if (color) {
    const [colorName, shade] = color.split('/') as [Color, ColorShade | undefined]

    return createCSSColorVariable(colorName, shade ?? defaultShade)
  }
}
